import { apiSlice } from "../../api/apiSlice";

export const MeterApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    meterInfoUpdate: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter/meter-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Meter-Info"],
    }),
  }),
});

export const { useMeterInfoUpdateMutation } = MeterApi;
