import { apiSlice } from "../../api/apiSlice";

export const acrelRealtimeDashboardApi = apiSlice.injectEndpoints({
  tagTypes: ["Meter-Info"],
  endpoints: (builder) => ({
    getAcrelMeterList: builder.query({
      query: () => "/apiV2/energy-meter-all-meter",
      providesTags: ["Meter-Info"],
    }),
    getMeterDetails: builder.query({
      query: (meter_id) =>
        `/apiV2/energy-meter/meter-details?meter_id=${meter_id}`,
    }),
    acrelRealtimeLineChartData: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter/real-time-chart-details",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetAcrelMeterListQuery,
  useGetMeterDetailsQuery,
  useAcrelRealtimeLineChartDataMutation,
} = acrelRealtimeDashboardApi;
