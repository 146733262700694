import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { AiFillDelete } from "react-icons/ai";

import { ThemeContext } from "../../../context/ThemeContext";
import { Table, Pagination, Input } from "rsuite";

import { useGetAcrelMeterListQuery } from "../../../features/acrel/realtimeDashboard/acrelRealtimeDashboardApi";
import { useMeterInfoUpdateMutation } from "../../../features/acrel/meter/MeterApi";

import Swal from "sweetalert2";
import useTitle from "../../../hooks/useTitle";

const { Column, HeaderCell, Cell } = Table;

function MeterUpdate() {
  useTitle("Meter Update");
  const theme = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});
  const [tableLoading, setTableLoading] = useState(true);

  const { data: meterList, isLoading: meterListLoading } =
    useGetAcrelMeterListQuery();
  const [meterInfoUpdate] = useMeterInfoUpdateMutation();

  const [imageShow, setImageShow] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  let defaultData = [];
  let data = [];

  useEffect(() => {
    if (!meterListLoading) {
      setTableLoading(false);
    }
  }, [meterListLoading]);

  if (!meterListLoading) {
    if (meterList.success) {
      defaultData = [...meterList?.data];
      defaultData = defaultData?.map((meterInfo) => {
        const date = new Date(meterInfo.updated_at);
        const formattedDate = date.toLocaleString();
        return {
          ...meterInfo,
          updated_at: formattedDate,
        };
      });
    }

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  // const handleChange = (e) => {
  //   alert(e.target.name);

  //   setFile(e.target.files[0]);
  //   if (file) {
  //     setImageUrl(URL.createObjectURL(file));
  //   } else {
  //     setImageUrl(null);
  //   }
  // };

  // if (updatePremisesResult || result) {
  //   console.log(updatePremisesResult || result);
  // }

  const handleClose = () => {
    setShow(false);
    setImageShow(false);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    meterInfoUpdate(updateFormData)
      .unwrap()
      .then((payload) => setResponseMessage(payload?.message))
      .catch((error) => setResponseMessage(error?.status));
  };
  const handleEdit = (id) => {
    let formData = defaultData.find((object) => object.id == id) || {};

    setUpdateFormData(formData);
  };
  const dataChangeHandler = (e) => {
    setResponseMessage(""); //to hide form submit message on change

    setUpdateFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };

  const imageModalOutsideClick = () => {
    setImageShow(false);
  };

  // const handleDelete = (id) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       deleteBill({ id: id })
  //         .unwrap()
  //         .then((payload) => {
  //           Swal.fire("Deleted!", "Your item has been deleted.", "success");
  //           console.log(payload?.message);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }
  //   });
  // };

  return (
    <div className={`row row-sm ${theme} `}>
      <div
        className="body-text"
        style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "1rem" }}
      >
        Meter Update
      </div>
      {/* <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          className="btn btn-primary btn-md "
          onClick={() => {
            setShow(true);
            setCreateFormSelector(true);
            setUpdateFormData({});
          }}
        >
          Bill Create
        </button>
      </div> */}
      <Modal
        show={imageShow}
        onHide={handleClose}
        className={`${theme} `}
        onExited={imageModalOutsideClick}
        centered
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>Image</Modal.Title>

            <RxCross2 onClick={imageModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <img
              className="image_inside_modal"
              src={imageUrl}
              alt="modal_image"
            ></img>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {/* {createFormSelector ? "Create Bill" : "Update Bill"} */}
              Update Meter Info
            </Modal.Title>
            <div style={{ color: "white" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    {/* <div class="form-group">
                      <label class="form-label" for="">
                        Select Meter <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        required
                        name="meter_id"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.meter_id || ""}
                      >
                        <option label="Choose one"></option>
                        {meterList?.data?.map((meterInfo) => {
                          return (
                            <option value={meterInfo?.id}>
                              {meterInfo?.meter_name}
                            </option>
                          );
                        })}
                      </select>
                    </div> */}
                    <div className="form-group">
                      <label>
                        Meter Id <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meter Id"
                        name="combined_id"
                        defaultValue={updateFormData?.combined_id || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                        readOnly
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Meter Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meter Name"
                        name="meter_name"
                        defaultValue={updateFormData?.meter_name || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label class="form-label" for="">
                        Phase <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        required
                        name="phase"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.phase || ""}
                      >
                        <option label="Choose one"></option>

                        <option value="1">1 Phase</option>
                        <option value="3">3 Phase</option>
                      </select>
                    </div>

                    {/* <div className="form-group">
                      <label>Description</label>
                      <Input
                        name="description"
                        as="textarea"
                        rows={3}
                        placeholder="Description"
                        defaultValue={updateFormData?.description || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div> */}

                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table height={500} data={data || []} loading={tableLoading}>
            <Column
              width={50}
              align="center"
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Meter Id</HeaderCell>
              <Cell dataKey="combined_id" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Meter Name</HeaderCell>
              <Cell dataKey="meter_name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Phase</HeaderCell>
              <Cell dataKey="phase" />
            </Column>

            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={100}
              align="center"
              // flexGrow={1}
              className={`body-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
            {/* <Column
              width={100}
              align="center"
              // flexGrow={1}
              className={`body-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Delete</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="delete"
                    onClick={() => {
                      // setShow(true);
                      // setCreateFormSelector(false);
                      handleDelete(rowData.id);
                    }}
                  >
                    <AiFillDelete />
                  </div>
                )}
              </Cell>
            </Column> */}
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeterUpdate;
